import Vue from 'vue'
import VueRouter from 'vue-router'
import CheckCode from '../views/CheckCode.vue'
import Error404 from '../views/Error404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/appsumo/:appsumoCode',
    component: CheckCode
  },
  {
    path:'*',
    component: Error404
  }
]

// const router = new VueRouter({
//   routes
// })

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router
