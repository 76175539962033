import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyD_ahCme3UnFV-8KvFyu_ndyG2VJR7XOUs",
  authDomain: "kpibees-appsumo-select.firebaseapp.com",
  projectId: "kpibees-appsumo-select",
  storageBucket: "kpibees-appsumo-select.appspot.com",
  messagingSenderId: "483778752392",
  appId: "1:483778752392:web:aed56e219dfd9d1e35543d",
  measurementId: "G-T5QLY4BGS0"
});

export default firebase;
