<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
    >

      <div class="d-flex align-center logo-wrapper">
        <v-img
          alt="KPIBees Logo"
          class="shrink mr-2 logo-wrapper_logo"
          contain
          :src="require('./assets/kpibees-logo.svg')"
          transition="scale-transition"
          height="40"
        />

        <v-img
          alt="KPIBees Logo Compact"
          class="shrink mr-2 logo-wrapper_logo-mobile"
          contain
          :src="require('./assets/kpibees-logo-compact.svg')"
          transition="scale-transition"
          height="40"
        />

      </div>

      

      <v-spacer></v-spacer>

      <v-btn
        href="https://kpibees.com"
        target="_blank"
        color="#f8bb02"
        text
      >
        <span class="mr-2">KPIBees</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>

      <v-btn v-if="loggedIn"
        @click="logout"
        color="#f8bb02"
        text
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <GoogleAuth v-if="!loggedIn"
        :login="login"
        :userProfile="userProfile"
        :loggedIn="loggedIn"
      />
      <StackCodes v-if="loggedIn" />


    </v-main>
  </v-app>
</template>

<style lang="scss">
  .logo-wrapper{
    &_logo-mobile{
      display: none !important;
    }
  }
  @media only screen and (max-width: 600px){
    .logo-wrapper{
      &_logo{
        display: none !important;
      }
      &_logo-mobile{
        display: unset !important;
      }
    }
  }
</style>



<script>
import { mapActions, mapGetters } from 'vuex';
import GoogleAuth from '@/components/googleAuth/GoogleAuth.vue';
import StackCodes from '@/components/stackCodes/StackCodes.vue';

export default {
  name: 'App',
  components: {
    GoogleAuth,
    StackCodes
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
      loggedIn: 'loggedIn',
      codes: 'codes'
    })
  },
  methods: {
    ...mapActions('user', {
      login: 'login',
      logout: 'logout'
    })
  },
  data: () => ({
    //
  }),
  mounted() {
    this.$intercom.boot();
  }
};
</script>


