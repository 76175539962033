<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/appsumo-logo.png')"
          class="my-3"
          contain
          height="70"
        />
      </v-col>
      <v-col
        class="mb-4"
        cols="12"
      >
        <v-row justify="center">
          <p>This page does not exist. Please send us a message on chat or at support@kpibees.com if you think this is a mistake.</p>
          <p>If you purchased an appsumo code, please visit https://appsumo-kpibees.ue.r.appspot.com/appsumo/{appsumo-code}</p>
        </v-row>
        <v-row justify="center">
          <v-btn
            href="https://gsuite.google.com/marketplace/app/kpibees/623897559595"
            target="_blank"
            color="#f8bb02"
            text
          >
            <span class="mr-2">KPIBees Add-on</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-btn
            href="https://kpibees.com"
            target="_blank"
            color="#f8bb02"
            text
          >
            <span class="mr-2">KPIBees Website</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>

export default {
  name: "Error404",
}
</script>
<style lang="scss" scoped>
</style>
