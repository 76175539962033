<template>
  <div>
    {{loggedIn}}
    <GoogleAuth
      :login="login"
      :logout="logout"
      :userProfile="userProfile"
      :loggedIn="loggedIn"
    />


  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import GoogleAuth from '@/components/googleAuth/GoogleAuth.vue';

export default {
  name: "CheckCode",
  data: () => ({
  }),
  components: {
    GoogleAuth
  },

  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
      loggedIn: 'loggedIn'
    })
  },
  methods: {
    ...mapActions('user', {
      login: 'login',
      logout: 'logout'
    })
  },
  beforeMount() {
  }
}
</script>
<style lang="scss" scoped>
.social-button {
  width: 75px;
  background: white;
  padding: 10px;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  outline: 0;
  border: 0;
}
.social-button:active {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}
.social-button img {
  width: 100%;
}
</style>
