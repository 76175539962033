<template>
  <v-container>
    <v-row>

      <v-col
      class="col-12 loginHeader"
        xs="12"
        sm="12"
        md="6"
        xl="4"
        offset-md="3"
        offset-lg="3"
        offset-xl="4"
         >
        <img class="bee" src="../../assets/bee-with-bucket.png" alt="bee">
        <h2>Login to claim your</h2>
        <h1>AppSumo Lifetime Deal</h1>

        <div class='g-sign-in-button' @click="login">
          <div class=content-wrapper>
              <div class='logo-wrapper'>
                  <img src='https://developers.google.com/identity/images/g-logo.png'>
              </div>
              <span class='text-container'>
            <span>Sign in with Google</span>
          </span>
          </div>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  export default {
    data: () => ({
      justify: [
        'start',
        'center',
        'end',
        'space-around',
        'space-between',
      ],
    }),
  }
</script>

<script>
  //import firebase from 'firebase';

  export default {
    name: 'GoogleAuth',
    props: ['login', 'userProfile', 'loggedIn'],
    data: () => ({
    }),
    computed: {
    },
  }
</script>

<style lang="scss">
  @import './googleAuth.scss'

</style>
