import firebase from '@/firebase';
import 'firebase/functions'
//const functions = firebase.functions();

//const db = firebase.firestore();

const state = {
  appLoaded: false,
  loggedIn: false,
  userProfile: {},
  codes: [],
  codesCount: 0,
  accountsApplied: [],
  accountsAvailable: [],
  plans: [
    "",                    // 0
    "Lifetime PRO 300",    // 1
    "Lifetime PRO 1000",   // 2
    "Lifetime PRO 3500",   // 3
    "Lifetime PRO 5000",   // 4
    "Lifetime PRO 10000",  // 5
    "Lifetime PRO 10000",  // 6
    "Lifetime PRO 10000",  // 7
    "Lifetime PRO 10000",  // 8
    "Lifetime PRO 10000",  // 9
    "Lifetime PRO 10000"   // 10
  ],
  accounts: [
    0, // 0
    1, // 1
    1, // 2
    1, // 3
    1, // 4
    2, // 5
    3, // 6
    4, // 7
    5, // 8
    7, // 9
    10 //10
  ]
};

const getters = {
  userProfile: ({userProfile}) => userProfile,
  loggedIn: ({loggedIn}) => loggedIn,
  codes: ({codes}) => codes,
  codesCount: ({codesCount}) => codesCount,
  plans: ({plans}) => plans,
  accounts: ({accounts}) => accounts,
  accountsApplied: ({accountsApplied}) => accountsApplied,
  accountsAvailable: ({accountsAvailable}) => accountsAvailable,
  appLoaded: ({appLoaded}) => appLoaded


};

const mutations = {
  USER_DETAILS(state, userProfile) {
    console.log(userProfile);
    state.loggedIn = true;
    state.userProfile = {
      name: userProfile.displayName,
      picture: userProfile.photoURL,
      email: userProfile.email,
      uid: userProfile.uid
    };
    state.codesCount = userProfile.codesCount;
  },
  SET_APP_LOADED(state, appLoaded) {
  state.appLoaded = appLoaded;
  },
  ADD_CODES(state, codes) {
    state.codes = codes;
  },
  CHANGE_CODE_COUNT(state, codesCount) {
    state.codesCount = codesCount;
  },
  CHANGE_ACCOUNTS_APPLIED(state, accountsApplied) {
    state.accountsApplied = accountsApplied;
    console.log('accountsApplied', accountsApplied);
  },
  CHANGE_ACCOUNTS_AVAILABLE(state, accountsAvailable) {
    state.accountsAvailable = accountsAvailable;
    console.log('accountsAvailable', accountsAvailable);
  },
  LOGOUT(state) {
    state.loggedIn = false;
    state.userProfile = {};
  }
};

const actions = {
  async login(store) {
    console.log("store log in");

    if (store.state.loggedIn)
      return;

    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(provider);
    } catch(error) {
      console.log(error);
    }
  },
  async logout() {
    try {
      await firebase.auth().signOut();
    } catch(error) {
      console.log(error);
    }
  },
  async addCode(store, code) {
    const addCodeF = firebase.functions().httpsCallable('addCode');
    return addCodeF({ code: code }).then(() => {
      return true;
    })
    .catch(error => {
      return error.message;
    });
  },
  async addAccount(store, email) {
    const addAccountF = firebase.functions().httpsCallable('addAccount');
    return addAccountF({ email: email }).then(() => {
      return true;
    })
    .catch(error => {
      return error.message;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
