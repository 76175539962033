<template>
  <div>

    <ul class="accountsAvailableList" v-if="codesCount == 1">
      <li>
        <p>
          <span class="stackingOfferItem">300 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">250,000 Query rows limit</span>
        </p>
      </li>
    </ul>

    <ul class="accountsAvailableList" v-if="codesCount == 2">
      <li>
        <p>
          <span class="stackingOfferItem">1,000 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">500,000 Query rows limit</span>
        </p>
      </li>
    </ul>

    <ul class="accountsAvailableList" v-if="codesCount == 3">
      <li>
        <p>
          <span class="stackingOfferItem">3,500 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">750,000 Query rows limit</span>
        </p>
      </li>
    </ul>

    <ul class="accountsAvailableList" v-if="codesCount == 4">
      <li>
        <p>
          <span class="stackingOfferItem">5,000 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">1,000,000 Query rows limit</span>
        </p>
      </li>
    </ul>

    <ul class="accountsAvailableList" v-if="codesCount == 5">
      <li>
        <p>
          <span class="stackingOfferItem">10,000 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">1,000,000 Query rows limit</span>
        </p>
      </li>
    </ul>

    <ul class="accountsAvailableList" v-if="codesCount == 6">
      <li>
        <p>
          <span class="stackingOfferItem">10,000 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">1,000,000 Query rows limit</span>
        </p>
      </li>
    </ul>

    <ul class="accountsAvailableList" v-if="codesCount == 7">
      <li>
        <p>
          <span class="stackingOfferItem">10,000 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">1,000,000 Query rows limit</span>
        </p>
      </li>
    </ul>

    <ul class="accountsAvailableList" v-if="codesCount == 8">
      <li>
        <p>
          <span class="stackingOfferItem">10,000 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">1,000,000 Query rows limit</span>
        </p>
      </li>
    </ul>


    <ul class="accountsAvailableList" v-if="codesCount == 9">
      <li>
        <p>
          <span class="stackingOfferItem">10,000 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">1,000,000 Query rows limit</span>
        </p>
      </li>
    </ul>

    <ul class="accountsAvailableList" v-if="codesCount == 10">
      <li>
        <p>
          <span class="stackingOfferItem">10,000 Query credits</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Schedule automatic refresh Hourly</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Access to all connectors</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Sheets</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Unlimited Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">Email & Slack Reports</span>
        </p>
      </li>
      <li>
        <p>
          <span class="stackingOfferItem">1,000,000 Query rows limit</span>
        </p>
      </li>
    </ul>

  </div>



</template>

<script>
  export default {
    name: 'StackOffer',
    props: ['codesCount'],

  }
</script>
