import firebase from './firebase';
import store from './store';
const db = firebase.firestore();

// https://firebase.google.com/docs/auth/web/manage-users
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    store.commit(`user/USER_DETAILS`, user);

    db.collection("users").doc(user.uid).onSnapshot((doc) => {
      store.commit(`user/ADD_CODES`, doc.data().codes);
      store.commit(`user/CHANGE_CODE_COUNT`, doc.data().codesCount);
    });


    db.collection("users").doc(user.uid).collection("accounts").onSnapshot((snapshot) => {
      let accountsApplied = [];
      let accountsAvailable = [];
      snapshot.forEach(doc => {
        if (doc.data().assigned) {
          accountsApplied.push({
            id: doc.id,
            email: doc.data().email,
            plan: doc.data().plan
          })
        } else {
          accountsAvailable.push(doc.id);
        }
      });
      store.commit(`user/CHANGE_ACCOUNTS_APPLIED`, accountsApplied);
      store.commit(`user/CHANGE_ACCOUNTS_AVAILABLE`, accountsAvailable);
      store.commit(`user/SET_APP_LOADED`, true);
    });


  } else {
    store.commit(`user/LOGOUT`);
  }
});
