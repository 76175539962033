<template>
  <v-container>

    <section class="first-stage" v-if="appLoaded">
      <v-row class="text-center">
        <v-col
        xl="10"
        offset-xl="1"
        class="col-12 card">
          <div class="title">
            <h2 id="stackingOnEmail">Redeem codes for account <br>
            <span class="email">{{userProfile.email}}</span>
            </h2>
          </div>

          <div v-if="codesCount < 10">
            <v-form v-model="valid" ref="form">
              <v-text-field
                v-model="newCode"
                ref="newCode"
                label="Add a code"
                required
              ></v-text-field>
              <v-btn
                :loading="reqNewCodeLoading"
                @click="submitNewCode"
              >
                Add Code
              </v-btn>
              <p class="red--text">{{ errorMessage }}</p>
            </v-form>
          </div>
        </v-col>

      </v-row>
    </section>

    <section class="second-stage">
      <v-row>

        <v-col
        v-if="codesCount"
        xl="4"
        lg="5"
        md="6"
        sm="12"
        xs="12"
        offset-xl="1"
        class="left-side">
          <div class="title">
            <img src="../../assets/icon-history.svg" alt="history">
            Stacking history
          </div>
          <div class="stackedCodesHeader">
            You've stacked {{codesCount}} code{{codesCount > 1 ? "s" : ""}}:
          </div>
          <div>
            <ol class="stackedCodes">
              <li v-for="code in codes" :key="code">
                <span>{{ code }}</span>
              </li>
            </ol>
          </div>
          <div class="stackingOfferDetails">
            This gives you access to <span class="stackedPlan">{{plans[codesCount]}}</span> for <span class="stackedPlan">{{accounts[codesCount]}}</span> account{{accounts[codesCount] > 1 ? "s" : ""}}:
          </div>

          <StackOffer v-bind:codesCount="codesCount" />

          <hr v-if="accountsApplied.length">

          <div v-if="accountsApplied.length" class="ApplyCodesHeader">
            Offer applied to the following account{{accountsAvailable.length > 1 ? "" : "s"}}:
          </div>
          <div v-if="accountsApplied.length > 0">
            <ul class="accountsAvailableList">
              <li v-for="account in accountsApplied" :key="account.email">
                <p>
                  <span class="email">{{ account.email }}</span>
                  <span class="plan">{{ plans[codesCount] }}</span><v-icon>mdi-check</v-icon>
                </p>
              </li>
            </ul>
          </div>
        </v-col>

        <v-col
        xl="6"
        lg="7"
        md="6"
        sm="12"
        xs="12"
        v-if="codesCount"
        class="right-side">
          <div v-if="accountsAvailable.length">
              <h2 id="stackingOnEmail">Apply offer to your account{{accounts.length > 1 ? "s" : ""}}</h2>
              <div class="guidence">
                <div class="guidence_title">
                  <img src="../../assets/icon-info-circle-yellow.svg" alt="info">
                  <b>To apply this offer to an account you must:</b>
                </div>
                <div class="guidence_steps">
                  <a href="https://gsuite.google.com/marketplace/app/kpibees/623897559595" target="_blank"> Install</a> KPIBees on your Google Account.
                  <br> Then open <a href="https://docs.google.com/spreadsheets/u/2/create" target="_blank">any spreadsheet</a> and launch KPIBees once (Add-ons > KPIBees > Launch). <b>This step is required.</b>
                </div>


              </div>
              <div v-if="accountsAvailable.length > 0" class="ApplyToAccountsHeader">
                Add <span class="stackedPlan">{{ plans[codesCount] }}</span> to the following email:
              </div>
              <div v-if="accountsAvailable.length > 0">
                <v-text-field
                  v-model="newAccount"
                  ref="newAccount"
                  label="Add account"
                  required
                ></v-text-field>
                <v-btn
                  :loading="reqAddAccountLoading"
                  @click="submitAddAccount"
                >
                  Add Account
                </v-btn>
                <p class="red--text">{{ errorMessageAccount }}</p>
              </div>
            </div>
            <div class="msgAccountApplied" v-else>
              <img src="../../assets/flame.svg" alt="flame">
              <h1 id="stackingOnEmail">All accounts were redeemed</h1>
              <p v-if="codesCount < 10">Stack to get more...</p>
            </div>
        </v-col>
      </v-row>

    </section>
    <v-row justify="center" v-if="accountsApplied.length" class="footerinfo">
      <div class="checkSubscriptionHeader">
        <img src="../../assets/icon-info-circle-black.svg" alt="info">
        <div>You can check your subscription in <a href="https://docs.google.com/spreadsheets/u/2/create" target="_blank"> any spreadsheet</a>, by going to Add-ons > KPIBees > Premium.</div>
      </div>
    </v-row>

  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import StackOffer from '@/components/stackCodes/StackOffer.vue';

  export default {
    name: 'StackCodes',
    components: {
      StackOffer
    },
    data: () => ({
      valid: false,
      newCode: "",
      newAccount: "",
      errorMessage: "",
      reqNewCodeLoading: false,
      reqAddAccountLoading: false,
      errorMessageAccount: "",
      emailValidator: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, //eslint-disable-line
    }),
    computed: {
      ...mapGetters('user', {
        userProfile: 'userProfile',
        loggedIn: 'loggedIn',
        codes: 'codes',
        codesCount: 'codesCount',
        plans: 'plans',
        accounts: 'accounts',
        accountsApplied: 'accountsApplied',
        accountsAvailable: 'accountsAvailable',
        appLoaded: 'appLoaded'
      })
    },
    methods: {
      ...mapActions('user', {
        addCode: 'addCode',
        addAccount: 'addAccount'
      }),
      submitNewCode () {
        this.newCode = this.newCode.trim();
        if (this.newCode.length > 0) {
          this.reqNewCodeLoading = true;
          this.addCode(this.newCode).then((res) => {
            if (res == true) {
              this.errorMessage = "";
              this.newCode = "";
            }
            else {
              this.errorMessage = res;
            }
            this.reqNewCodeLoading = false;
          });
        }

      },
      submitAddAccount () {
        this.newAccount = this.newAccount.trim();
        if (!this.emailValidator.test(this.newAccount)) {
          this.errorMessageAccount = "Invalid email.";
        }
        else if (this.newAccount.length > 0) {
          this.errorMessageAccount = "";
          this.reqAddAccountLoading = true;
          this.addAccount(this.newAccount).then((res) => {
            if (res == true) {
              this.newAccount = "";
            }
            else {
              this.errorMessageAccount = res;
            }
            this.reqAddAccountLoading = false;
          });
        }

      }
    },
  }
</script>

<style lang="scss">
 @import './stackCodes.scss'



</style>
